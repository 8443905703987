@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap');

.insuranceLogos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 1rem;
}


.insurance-logos {
    position: absolute;
    margin: 3rem;
    height: 110px;
    width: 300px;
    border: 1px solid rgb(224, 214, 214);
    border-radius: 10px;
}

.main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.image {
    width: 80%;
}


@media screen and (max-width:940px) {
    .about .content {
    width: 100%;
    }

    .image {
        width: 60%;
    }

    .insuranceLogos {
        margin-top: 10rem;
        text-align: center;
        width: 100%;
    }
}

