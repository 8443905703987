@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

/* img tags */

.imagesContainer {
	width: 100%;
	height: 100vh;
	position: relative;
	overflow: hidden;
}


.hero-caption {
  position: absolute;
  z-index: 5;
  bottom: 20%;
  padding: 1rem;
  width: 100%;
  text-align: center;
}

.hero-caption span {
  color: rgb(197, 136, 24);
}

.hero-caption h1 {
  color: rgb(255, 255, 255);
  font-size: 4.5rem;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  line-height: 3.7rem;
}


.hero-caption h2 {
  color: white;
  font-size: 1.2rem;
  letter-spacing: 3px;
  text-transform: uppercase;
  padding: 1rem 0;
  font-weight: normal;
}

.hero-caption a {
  font-size: 2rem;
}

.imagesContainer img {
	width: 100%;
  height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

/* images using CSS */
.imageDiv {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-size: cover;
}

.image1 { background: linear-gradient(to top, #00000088, #00000086), url('../../assets/hero1.webp')no-repeat center center/cover; }

.image2 { background: linear-gradient(to top, #00000081, #00000069), url('../../assets/hero2.webp')no-repeat center center/cover; }


/* animation styles */
@keyframes fadeIn {
	0% { opacity: 0; }
	20% { opacity: 0; }
	60% { opacity: 1; }
	100% { opacity: 1; }
}

.fadeInClass {
	animation-name: fadeIn;
	animation-duration: 7s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-timing-function: linear;
}



@media screen and (max-width: 940px) {
  .hero-caption {
    width: 100%;
    bottom: 10%;
    left: 0%;
    text-align: center;
  }

  .hero-caption h1 {
    font-size: 3rem;
  }

  .hero-caption h2 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 500px) {
  .hero-caption h1 {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }

  .hero-caption h2 {
    font-size: .8rem;
  }


  .hero-caption a {
    font-size: 1.5rem;
  }

}