@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.navbar {
    width: 100%;
    height: auto;
    z-index: 10;
    background: #0000009d;
    position: absolute;
}

.navbar .container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.navbar-logo {
  z-index: 9999999;
  width: 120px;
}

.navbar-logo img {
  width: 100%;
}

.navbar .icon {
  font-size: 1.4rem;
  color: #ffffff;
}

.navbar .logo .icon {
    font-size: 3rem;
    margin-right: 1rem;
    color: var(--primary-color);
}

.hamburger {
    display: none;
    padding: 1rem;
}

.navbar .nav-menu {
    height: 100%;
    align-items: center;
    display: flex;
    padding: 1rem;
}
.navbar li {
    width: max-content;
    padding: .3rem 1rem;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}


.navbar li a {
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;

}

  .menu-item .sub__menus li a {
    color: #ffffff;
    font-size: 13px;
    padding: -1rem ;
  }

  .menu-item .sub__menus li a:hover {
    color: var(--primary-color);
  }

  li .submenu-header {
    font-size: 14px;
    color: rgb(36, 131, 27);
    font-weight: bolder;
    text-decoration: underline;
    cursor: pointer;
  }


/* -----------------------------------------------------> SUB MENU */
.sub__menus {
    position: absolute;
    display: none;
    background: rgb(46, 44, 44);
    border-radius: 5px;
    width: 300px;
    padding: 2px 0 0 0;
    margin-top: 6px;
    z-index: 1000;
  }



  /* -----------------------------------------------------> DROPDOWN ARROW */
  .sub__menus__arrows svg, .sub__menus__arrows__full svg {
    top: 10px;
    font-size: 12px;
  }



@media screen and (max-width: 1200px) {
    .navbar .hamburger {
        display: block;
        z-index: 15;
    }

    .navbar li a {
      color: rgb(250, 250, 250);
    }

    .navbar .nav-menu {
        position: absolute;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        top: 0;
        left:-100%;
        align-items: center;
        justify-content: center;
        background: rgb(0, 0, 0);
        transition: 0.5s ease-in;
        color: white;
    }

    .navbar .active {
        left: 0;
    }

    .navbar li {
        font-size: 1rem;
    }

    .navbar button {
        font-size: 1.8rem;
    }

    .menu-item .sub__menus li a {
        font-size: 14px;
      }

      li .submenu-header {
        font-size: 14px;
      }

      #submenu-item li {
        padding: .2rem 1rem;
      }

      .sub__menus {
        width: max-content;
      }

}





