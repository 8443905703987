@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.mh-card-wrap
{
  width:100%;
  display:flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  align-items:space-around;
}

.mh-card
{
  width:300px;
  border-radius: 10px;
}

.mh-card img {
  width:100%;
  border-radius: 5px;
}

.mh-card h1 {
  color: var(--primary-color);
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
  padding: .5rem;
}

.mh-cards-header {
    width: auto;
    padding: 20px;
    text-align: center;
    margin: 2rem auto;
}

.mh-cards-header h1 {
  margin: 2rem auto;
  font-family: 'Montserrat', sans-serif;
}

.mh-cards-header p {
font-family: 'Montserrat', sans-serif;
}

@media (max-width: 940px) {
  .mh-card-wrap {
    width: 100%;
  }

}

