@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');



.TherapySA {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}
  
  .TherapySA:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

.TherapySA .content {
    display: flex;
    flex-wrap: nowrap;
    gap: 1rem;
    top: 10%;
    padding: 5rem 0;
}


.sa-box h1 {
    text-align: center;
}

.sa-box p {
    padding: .5rem;
    text-align: center;
}

/* LEFT */

.TherapySA .TherapySA-left {
    width: 60%;
    margin: auto;
    padding: 1rem;
}


.TherapySA .TherapySA-left h1 {
    color: rgb(77, 76, 76);
    font-size: 2rem;
    padding: 2rem 0;
}

.TherapySA .TherapySA-left p {
    color: rgb(77, 76, 76);
    font-size: 15px;
    text-align: left;
}


/* RIGHT */

 .TherapySA-right {
    width: 100%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    justify-content: center;
}


.sa-box {
    width: 350px;
}

.sa-box img {
    width: 100%;
    border-radius: 5px;
    transition: all .8s ease-in-out;
}

.sa-box img:hover {
    border-radius: 1rem;
}


.sa-box h1 {
    color: var(--primary-color);
    padding: .2rem 0 1rem 0;
    font-size: 1rem;
    font-weight: normal;
}

.sa-box p {
    color: rgb(24, 23, 23);
    text-align: center;
    padding: 1rem;
}

.TherapySA-btn {
    padding: 1rem 0;
}

.TherapySA-btn button {
    background-color: var(--primary-color);
    color: white;
    cursor: pointer;
    padding: .8rem 2rem;
    border: none;
    border-radius: 5px;
}

@media screen and (max-width:1200px) {
    
    .TherapySA .TherapySA-left {
        width: 100%;
    }

    .TherapySA .content {
        flex-wrap: wrap;
        text-align: center;
        padding: 1rem;
    }

    .TherapySA .content p {
        text-align: center;
        padding: 0 1rem;
    }

}


@media screen and (max-width: 940px) {


    .TherapySA-right  .box{
        width: 100vw;
    }
    
    .TherapySA .TherapySA-left h1{
        font-size: 2rem;
    }

    .TherapySA .TherapySA-left {
        width: 90vw;
    }

    .TherapySA .TherapySA-right {
        width: 90%;
    }


    
}

@media screen and (max-width: 600px) {

}

