@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');



.TherapyMH {
    width: 100%;
    height: auto;
    position: relative;
    justify-content: center;
    background: #181818;
}
  
  .TherapyMH:before {
    content: '';
    position: absolute;
    /* background: url('../../assets/therapyMH-min.jpg') no-repeat center top/cover; */
    height: 100%;
    width: 100%;
    z-index: -1;
  }

.TherapyMH .content {
    top: 10%;
    padding: 5rem 0;
    text-align: center;
}


/* HEADER */

.TherapyMH .TherapyMH-header {
    width: 100%;
    text-align: center;
}


.TherapyMH .TherapyMH-header h1 {
    color: var(--primary-color);
    font-size: 1rem;
    letter-spacing: 8px; 
}

.TherapyMH .TherapyMH-header p {
    color: rgba(255, 255, 255, 0.808);
    font-size: 2rem;
    letter-spacing: 5px;
}




/* FLEX */

 .TherapyMH-flex {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 0 .5rem;
}

.TherapyMH-flex .mh-box {
    height: auto;
    width: 350px;
    border-radius: 1rem;
}

.mh-box {
    width: 200px;
}

.mh-box img {
    width: 100%;
    border-radius: 5px;
}

.mh-box h1 {
    font-size: 1rem;
    padding: .5rem;
    color: rgba(255, 255, 255, 0.808);

}

.TherapyMH-btn button {
    margin: 3rem 0;
    background-color: var(--primary-color);
    color: white;
    cursor: pointer;
    padding: .8rem 2rem;
    border: none;
    border-radius: 5px;
}



@media screen and (max-width:940px) {


    .TherapyMH .TherapyMH-header {
        width: 100%;
    }

    .TherapyMH .content {
        flex-wrap: wrap;
        text-align: center;
        padding: 3rem 0;
    }

    .TherapyMH-flex {
        flex-wrap: wrap;
    }

    .TherapyMH-flex .box{
        width: 100vw;
        flex-wrap: wrap;
    }

    .TherapyMH .TherapyMH-header h1{
        font-size: 1rem;
    }

    .TherapyMH .TherapyMH-header p {
        font-size: 1.5rem;
        padding: 1rem;
    }

    .TherapyMH .TherapyMH-header {
        width: 100%;
    }

    .TherapyMH .TherapyMH-flex {
        width: 90%;
    }

    .TherapyMH-btn {
        padding: 3rem 0 0 0;
    }

}

@media screen and (max-width:600px) {

}

