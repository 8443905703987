@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.sectionbg {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: rgba(29, 28, 29, 0);
  }
  
  .sectionbg:before {
    content: '';
    position: absolute;
    background: url('../../assets/bg.png') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    opacity: .03;
  }

.wrapper1 {
    padding: 5rem 0;
    background: #ffffff;
}

.wrapper-content {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
}

.wrapper-content-left {
    width:40%;
    text-align: center;
}

.wrapper-content-left img {
    width: 100%;
    border-radius: .5rem
    ;
}

.wrapper-content-right {
    width: 600px;
    margin: auto;
    padding: 0 2rem;
}

.wrapper-content-right h2 {
    font-size: 2rem;
    color: #5a5c82;
}

.wrapper-content-right h1 {
    text-transform: uppercase;
    color: var(--primary-color);
    font-size: 1rem;
    letter-spacing: 8px; 
}


@media screen and (max-width:1250px)  {
    .wrapper-content-right {
        width: 100%;
        padding: 1rem 2rem;
    }

    .wrapper-content-right h1, h2, p {
        text-align: center;
    }

    .wrapper-content-right h2 {
        font-size: 2rem;
    }

}

@media screen and (max-width: 700px) {
    .wrapper-content-left {
        width: 100vw;
    }
    .wrapper-content-left img {
        padding: 2rem;
    }

    .wrapper-content-right p {
        text-align: justify;
    }

    .wrapper-content-right h2 {
        font-size: 1.5rem;
    }
}
